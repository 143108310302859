import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Fellows = makeShortcode("Fellows");
const Projects = makeShortcode("Projects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kernel-原则",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel 原则`}<a parentName="h1" {...{
        "href": "#kernel-%E5%8E%9F%E5%88%99",
        "aria-label": "kernel 原则 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel 的主要目标很简单：`}<strong parentName="p">{`一起学习`}</strong>{`。我们通过精心设计的同伴学习环境以实现它。在创造这样的环境时，我们遵循两个原则，一个针对 "web3"，另一个更个人化：`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`不伤害`}</strong></p>
      <p><strong parentName="p">{`玩，永无止境`}</strong></p>
    </Process>
    <p>{`根据这两个核心追求，我们可以衍生出 16 个更细致的原则。`}</p>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h3 {...{
          "id": "️-web3-原则",
          "style": {
            "position": "relative"
          }
        }}>{`⌛️ Web3 原则`}<a parentName="h3" {...{
            "href": "#%EF%B8%8F-web3-%E5%8E%9F%E5%88%99",
            "aria-label": "️ web3 原则 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <ol>
          <li parentName="ol">{`信任不仅限于交易。`}</li>
          <li parentName="ol">{`共享的真相创造价值。`}</li>
          <li parentName="ol">{`学习限制，然后自由。`}</li>
          <li parentName="ol">{`货币即言论。`}</li>
          <li parentName="ol">{`激励影响一切。`}</li>
          <li parentName="ol">{`解放激进的机构。`}</li>
          <li parentName="ol">{`在经济上抵制审查。`}</li>
          <li parentName="ol">{`使能力规模化。`}</li>
        </ol>
      </Box>
      <Box mdxType="Box">
        <h3 {...{
          "id": "-个人化原则",
          "style": {
            "position": "relative"
          }
        }}>{`🌈 个人化原则`}<a parentName="h3" {...{
            "href": "#-%E4%B8%AA%E4%BA%BA%E5%8C%96%E5%8E%9F%E5%88%99",
            "aria-label": " 个人化原则 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <ol>
          <li parentName="ol">{`把玩模式。`}</li>
          <li parentName="ol">{`开发工具，抵达意义。`}</li>
          <li parentName="ol">{`提出更好的问题。`}</li>
          <li parentName="ol">{`首先考虑你的意图。`}</li>
          <li parentName="ol">{`听更好的故事，讲更好的故事。`}</li>
          <li parentName="ol">{`在一起时，个体就能够很好地治理。`}</li>
          <li parentName="ol">{`学习如何爱上学习。`}</li>
          <li parentName="ol">{`给予神圣。`}</li>
        </ol>
      </Box>
    </Column>
    <h2 {...{
      "id": "遇见-kernel-伙伴们",
      "style": {
        "position": "relative"
      }
    }}>{`遇见 Kernel 伙伴们`}<a parentName="h2" {...{
        "href": "#%E9%81%87%E8%A7%81-kernel-%E4%BC%99%E4%BC%B4%E4%BB%AC",
        "aria-label": "遇见 kernel 伙伴们 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Fellows mdxType="Fellows" />
    </Aligner>
    <h2 {...{
      "id": "先给予",
      "style": {
        "position": "relative"
      }
    }}>{`先给予`}<a parentName="h2" {...{
        "href": "#%E5%85%88%E7%BB%99%E4%BA%88",
        "aria-label": "先给予 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`在这里我们为彼此服务，因为我们是彼此的环境。真正的服务也引导我们走向任何有意义的自我认知的目标：实际上，没有所谓的他者。`}</p>
    <p>{`我们还有一个共同的外部目标：`}<strong parentName="p">{`支持自由货币的持续创造`}</strong>{`。在这个背景下，Kernel 是一份礼物。它确实源于此：Vivek 开始回馈一个曾赋予他生计和宝贵成长经历的社区。Andy 开始为 Vivek 提供实现这一愿景的教育模式。每一期 Kernel 都是由各种才华横溢的人精心打造的，我们独特，但有着与周围世界分享天赋的共同愿望。这是一种老派生活方式依然跳动的心脏，我们正慢慢将其带入数字世界。`}</p>
    <p>{`我们相互学习，奉献自己，直到自我和他者失去那种常常主宰其间互动的分离感。在这个过程中，有一种无法被给予或接受的真相。只有在你开始信任自己已有的价值时，它才会在你心中生根发芽。`}</p>
    <h2 {...{
      "id": "项目与团队",
      "style": {
        "position": "relative"
      }
    }}>{`项目与团队`}<a parentName="h2" {...{
        "href": "#%E9%A1%B9%E7%9B%AE%E4%B8%8E%E5%9B%A2%E9%98%9F",
        "aria-label": "项目与团队 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Projects mdxType="Projects" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      